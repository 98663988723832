import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { ModelCreate } from '../../services/srvc-cs-model-realm'
import FileCreateCX from '../../services/srvc-filecreate-cweb-xx'
import { GetUserForm } from '../../services/srvc-utilities'
import { Toast } from '../common/utils'
import FormNeeded from '../webx/form-needed'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import TaxxonCard from './TaxxonCard'
import { BASELINE, DOMAIN } from './truescore'

export default function FormatsCreateModuleCS () {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')
  const [memoType, setMemoType] = useState('')
  const [media, setMedia] = useState({})
  const [format, setFormat] = useState([])
  const [truescore, setTruescore] = useState([])
  const [trueScoreDomain, setTrueScoreDomain] = useState(DOMAIN)

  const [data, setData] = useState({
    name: '',
    memo: '',
    format: '',
    sort: '',
    start: '',
    end: '',
    bs: ''
  })
  const TS_ID = '53166c172634486ca283049c8820e8267'

  const handleTruscore = async item => {
    const temp = [...truescore]
    const index = temp.indexOf(item)
    if (index < 0) {
      temp.push(item)
    } else {
      temp.splice(index, 1)
    }
    setTruescore(temp)
  }
  useEffect(() => {
    const transformedItems = trueScoreDomain.map(item => {
      if (truescore.includes(item.item)) {
        return { ...item, selected: true }
      }
      return { ...item, selected: false }
    })

    setTrueScoreDomain(transformedItems)
  }, [truescore])

  const handleSubmit = async () => {
    setLoader(true)
    setSubmit(true)
    setMemo('Please wait...')
    if (!truescore.length && !data.bs) {
      setMemo('Please select at least 1 data')
      setMemoType('warning')
      return alert('Please select at least 1 data')
    }
    const tsPayload =
      DOMAIN.map(item =>
        truescore.includes(item.item) ? { ...item } : ''
      ).filter(item => item) || []

    const baseline = BASELINE.find(item => item.value === data.bs) || ''

    const datx = {
      name: data.name,
      memo: data.memo,
      valid: '24',
      role: 'user',
      consumer: asset.item,
      bsdata: tsPayload,
      bsmodel: baseline
    }

    const result = await ModelCreate({ data: datx, srvc: '******' })
    if (result.stat) {
      setDone(true)
      setMemo('Model Created')
      setMemoType('success')
    } else {
      setDone(false)
      setMemo('Model Creation Failed')
      setMemoType('danger')
    }

    setLoader(false)
  }

  const handleMediaBanner = async filedata => {
    setMedia({ link: filedata?.file.link, mime: filedata?.file?.mime })
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const BaseLineUi = () => {
    return (
      <>
        {BASELINE.map(item => (
          <div className='mb-3 form-check' key={item.item}>
            <input
              type='radio'
              style={{ cursor: 'pointer' }}
              id={item.item}
              className='form-check-input'
              value={item.value}
              checked={item.value === data.bs}
              name='bs'
              onChange={e => handleChange('bs', e.target.value)}
            ></input>
            <label
              className='form-label text-bold text-uppercase m-0 ms-3'
              htmlFor={item.item}
            >
              {item.name} <FormNeeded />
            </label>
          </div>
        ))}
      </>
    )
  }
  return (
    <>
      {/* info */}
      <div className='mx-3'>
        <h2 className='text-normal m-0'>New CreditScore Truescore Model</h2>
        <WebbDividerSmall />
      </div>

      <div
        className={
          submit
            ? 'back-color-wite p-3 rounded-xd'
            : 'back-color-wite p-3 rounded-xd'
        }
      >
        <div className={''}>
          <p className='text-normal m-0'>1. Enter Details</p>
          <WebbDividerSmall />

          <div className='mb-3 d-none'>
            <label className='form-label text-small'>
              Add Media <FormNeeded />
            </label>
            <FileCreateCX size='media-standard' media={handleMediaBanner} />
            <WebbDividerSmall />
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              Name <FormNeeded />
            </label>
            <input
              type='text'
              className='form-control height-md'
              style={{ fontSize: '0.9rem', height: '2.7rem' }}
              value={data.name}
              onChange={({ target }) => {
                handleChange('name', target.value)
              }}
              disabled={loader || submit}
              placeholder=''
            ></input>
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              Details <FormNeeded />
            </label>
            <textarea
              className='form-control'
              rows='3'
              style={{ fontSize: '0.9rem' }}
              value={data.memo}
              onChange={({ target }) => {
                handleChange('memo', target.value)
              }}
              disabled={loader || submit}
            ></textarea>
          </div>
        </div>

        <div className={''}>
          <WebbDividerMedium />
          <p className='text-normal m-0'>2. Select Data Model</p>
          <WebbDividerSmall />

          <div className='mb-3'>
            <label className='form-label text-small mb-3 d-none'>
              Select Domain <FormNeeded />
            </label>

            <BaseLineUi />
            <div className=''>
              <div className='row row-cols-2 row-cols-md-3 g-2'>
                {data?.bs &&
                  trueScoreDomain.map((item, i) => {
                    return (
                      <div className='col' key={i}>
                        <TaxxonCard
                          {...{
                            ...item,
                            handleFormat: handleTruscore,
                            selectedFormat: truescore
                          }}
                          key={item.item}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>

        <WebbDividerMedium />
        <Toast msg={memo} type={memoType} />

        <div className={submit ? 'd-none' : ''}>
          <WebbDividerMedium />
          <div className='d-flex justify-content-between'>
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type='button'
              disabled={loader || submit}
              onClick={() => {
                navigate(-1)
              }}
            >
              {loader ? 'Please Wait...' : 'Cancel'}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type='button'
              disabled={!data?.bs}
              onClick={() => {
                handleSubmit()
              }}
            >
              {loader ? 'Please Wait...' : 'Submit'}
            </button>
          </div>
        </div>
        <WebbDividerSmall />
      </div>
    </>
  )
}
