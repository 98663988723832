import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import FormatsViewSidebar from '../content/formats/formats-view-sidebar'
import PkgCodeModule from '../content/pkgs/pkg-code'
import PkgsViewConsumerModule from '../content/pkgs/pkgs-view'
import UserAvatar from '../content/webx/user-avatar'
import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbIcon from '../content/webx/webb-icon'
import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

export default function PkgsViewConsumer () {
  const { state } = useLocation()
  const metadata = {
    name: 'Pkg',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: 'text-color-main', size: 'text-icon-sm' }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {' '}
              <WebbHeader />{' '}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          )
        }}
        sidebar={{
          header: (
            <>
              <p className='text-normal text-bold m-0'>{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <FormatsViewSidebar {...{ ...state, status: { live: true } }} />
            </>
          ),
          footer: <>...</>
        }}
        content={{
          header: (
            <>
              <p className='m-0'>Packages Details</p>
            </>
          ),
          data: (
            <>
              <PkgsViewConsumerModule data={state} />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              {/* <FormatsActionsModule actions={actionList} /> */}
              <WebbDividerSmall />
              <PkgCodeModule data={state} />
            </>
          ),
          footer: <></>
        }}
      ></ContentFormat>
    </>
  )
}
