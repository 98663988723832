import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import FormNeeded from '../webx/form-needed'
import WebbDividerSmall from '../webx/webb-divider-sm'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

export default function FormatsSearchModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [search, setSearch] = useState()

  const [data, setData] = useState({
    start: new Date().toISOString().substring(0, 10),
    end: new Date().toISOString().substring(0, 10),
    assets: true,
    funds: true,
    active: true,
    draft: false,
    deleted: false,
    all: false
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  return (
    <>
      {/* info */}
      <div className=''>Search</div>

      {/* format */}
      {/* <WebbDividerSmall /> */}
      <div className='back-color-wite p-2 px-3 rounded-xd d-none'>
        <div className=''>
          <label className='form-label text-small'>
            Formats <FormNeeded />
          </label>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Academics</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                checked={data.assets}
                onChange={() => ''}
              ></input>
            </div>
          </div>
          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Work</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                onChange={() => ''}
                checked={data.funds}
              ></input>
            </div>
          </div>
          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Social</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                onChange={() => ''}
                checked={data.funds}
              ></input>
            </div>
          </div>
        </div>
      </div>

      {/* status */}
      <WebbDividerSmall />
      <div className='back-color-wite p-2 px-3 rounded-xd'>
        <div className=''>
          <label className='form-label text-small'>
            Status <FormNeeded />
          </label>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Live</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                onChange={() => ''}
                checked={data.active}
              ></input>
            </div>
          </div>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Draft</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                onChange={() => ''}
                checked={data.draft}
              ></input>
            </div>
          </div>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Deleted</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                onChange={() => ''}
                checked={data.deleted}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
