// assets
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

// import { AssetList } from "../../services/srvc-assets-realm";
import list from '../../data.static/data-network.json'

const media = {link: 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=826'}

// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect-modern-colorful-circular-blur-light-backdrop-vector-illustration_515038-7325.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect-modern-colorful-circular-blur-light-backdrop-vector-illustration_515038-9990.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect_515038-13016.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect_515038-10766.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect-modern-colorful-circular-blur-light-backdrop-vector-illustration_515038-966.jpg?w=996


export default function NetworkViewCreatorModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();
  const {id} = useParams()

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()


  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        // const result = await AssetList({data: {
        //   creator: '', user: asset.item, 
        //   index: 1, items: 25, 
        //   filters:{ name: {} }
        // }})
        // console.log (result)
        // const counts = await AssetListCounts({data: {}, srvc: '******'})
        // console.log (counts.data)

        // console.log (result)
        // const assets = Array.from(result.data.list, x => { 
        //   var countx = counts.data.find(z => z.asset == x.item)

        //   return {
        //   ...x, count: countx?.count || {users: 0, likes: 0, views: 0}
        // }})
        // console.log(assets)
        // if (result.stat) setData(result.data.list)

        setData(list.data.find(x => x.item == id))

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = async(item) => {
    // navigate(`/${asset.role}/ax/${item}`)
  }


  if (loader) return <>
    <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>
      
      <span className="align-middle text-lead">
        <i className="bx bxs-info-circle text-color-wait"></i>
      </span>
      <span className='ms-1 text-color-tone'>Please Wait...</span>
      
    </div>
  </>
 


  return (
    <>
      {/* info */}
    
      {/* asset media */}
      <div className="">
        
        <div className="media-banner d-none d-md-block">
          <img
            src={media.link} // Route of the image file
            layout='fill'
            className="img-fluid w-100 rounded-none shadow "
            alt="..."
          />
        </div>
        <div className="media-standard d-md-none">
          <img
            src={media.link} // Route of the image file
            layout='fill'
            className="img-fluid w-100 rounded-none shadow "
            alt="..."
          />
        </div>  

      </div>

      {/* asset details */}
      <div className="mb-3 back-color-wite p-3">
        <div className="mb-2"></div>
        
        <div className="mx-3">
          <p className="text-color-next m-0 mb-1">ID: {data?.number || '******'}</p>
          <h1 className="header-sm m-0 text-bold d-none d-md-block">{data?.user?.name}</h1>
          <h1 className="text-lead m-0 text-bold d-md-none">{data?.user?.name}</h1>

          <p className="text-normal m-0 d-none d-md-block">{data?.user?.mail}</p>
          <p className="m-0 d-md-none">{data?.user?.mail}</p>

        </div>

        <div className="mb-2"></div>
      </div>

    </>
  );

}