// network
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'


export default function TeamMemberAddModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState('');

  const [data, setData] = useState({
    name: '',
    mail: '',
    mobile: ''
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
       
        
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])        


  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.mail!=='') setForm(true);

  },[data]);

  const handleSubmit = async () => {

    setLoader(true);
    setSubmit(true);
    setMemo('Please wait...')
    
    const datx = {
    
    }

    // const result = await UserAccountCreate({data: datx, srvc: '******'})
    // console.log (result)
    
    
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }



  // if (loader) return <></>


  return (
  <>
    {/* info */}
    <div className="mx-3">
      <h2 className='text-normal m-0'>{'Grow Your Team'}</h2>
      
      <WebbDividerSmall/>
    </div>


    <div className={submit ? 'back-color-wite p-3 rounded-xd' : 'back-color-wite p-3 rounded-xd'}>

      <WebbDividerSmall/>
      <div className={''}>
        <div className="mb-3">  
          <label className="form-label text-small">Name <FormNeeded/></label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); }}
            disabled={loader || submit}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">  
          <label className="form-label text-small">Email <FormNeeded/></label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.mail}
            onChange={({ target }) => {handleChange("mail", target.value); }}
            disabled={loader || submit}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">  
          <label className="form-label text-small">Mobile </label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.mobile}
            onChange={({ target }) => {handleChange("mobile", target.value); }}
            disabled={loader || submit}
            placeholder="">
          </input>
        </div>        

      </div>

      <WebbDividerMedium />
      <div className="">
        <p className="m-0">{memo}</p>
      </div>

      <div className={submit ? 'd-none' : ''}>
        <WebbDividerMedium />
        <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
          type="button"
          disabled={loader || submit}
          onClick={()=> { navigate(-1)}}
        >{loader ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-primary border-none rounded-xx text-small`}
          type="button"
          disabled={!form || loader || submit}
          onClick={()=> { handleSubmit()}}
        >{loader ? 'Please Wait...' : 'Continue'}</button>

        </div>


      </div>

      <WebbDividerSmall />
    </div>


  </>

  )
}