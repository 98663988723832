// assets
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbLoaderMedium from '../webx/webb-loader-md'
import WebbLoaderSmall from '../webx/webb-loader-sm'
import WebbModuleInfo from '../webx/webb-module-info'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetList } from '../../services/srvc-assets-realm'
// import list from '../../data.static/data-assets.json'

export default function AssetsListCreatorModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [sort, setSort] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await AssetList({
          data: {
            creator: '',
            index: 1,
            items: 25,
            filters: { name: {} }
          }
        })
        console.log(result)

        if (result.stat) setData(result.data.list)
        const sortx = Array.from(result.data.list, x => {
          return {name: x.feature.format}
        })
        setSort(Array.from([...new Set(sortx.map(item => item.name))], x => { return {name: x}}))
        // console.log([...new Set(sortx.map(item => item.name))])

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  const handleClick = async item => {
    navigate(`/${asset.role}/assets/${item}`)
  }

  if (loader)
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-wait'></i>
          </span>
          <span className='ms-1 text-color-tone'>Please Wait...</span>
        </div>
      </>
    )
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-wait'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Assets Listed</span>
        </div>
      </>
    )

  return (
    <>
      {/* data */}
      <div className='d-none d-md-block'>
      {sort && sort.map((sortz, z) => (
        <div className="mb-3" key={z}>
          <h2 className="text-normal text-color-next mb-2">{sortz.name}</h2>
          {/* <p className="text-bold m-0 ms-3" key={z}>{sortz.name}</p> */}
          

          <div className='back-color-wite rounded-xd'>
            <WebbDividerSmall />
            <div className='row row-cols-1 row-cols-md-3 g-3'>
            {data && data.map((item, x) => (item.feature.format == sortz.name ?
              <div className="col m-0 mb-3" key={x}>
                
                <div className="card border rounded-xd h-100 cursor" onClick={()=> handleClick(item.item)}>
                <div className='media-standard rounded-xd rounded-bottom-none'>
                  <img src={item.media.link} // Route of the image file
                    layout='fill'
                    className='img-fluid w-100 rounded-none shadow rounded-bottom-none'
                    alt='...'
                  />
                  <div className='d-none btn back-color-main rounded-xx text-mini'>
                    {item.meta.sort}
                  </div>
                </div>

                <div className='p-3'>
                  <p className='text-mini m-0'>
                    {item?.webx?.number || '******'}
                  </p>
                  
                  <p className='text-bold text-color-next text-sm mb-1'>
                    {item.meta.name}
                  </p>
                  <p className='text-small text-md m-0'>{item.meta.memo}</p>
                </div>
                </div>
              </div>    
            :''))}
            </div> 
          </div>

          <WebbDividerSmall />
        </div>
      ))}
      </div>

      <div className="d-md-none">
      {sort && sort.map((sortz, z) => (
        <div className="mb-3" key={z}>
          <p className="text-bold m-0 ms-3" key={z}>{sortz.name}</p>
          <WebbDividerSmall />

          <div className='back-color-wite rounded-xd p-3 '>

          {data && data.map((item, x) => (item.feature.format == sortz.name ?
            <div className="" key={x}>
            <div className="d-flex justify-content-start" onClick={()=> handleClick(item.item)}>
              <div className="">
                <div className="media-cube rounded-xd" style={{width: '60px', height: '60px'}}>
                  <img src={item.media.link} // Route of the image file
                    layout='fill'
                    className='img-fluid'
                    alt={item?.meta?.name || '******'}
                  />
                </div>
              </div>
              <div className="ms-2 w-100">
                <p className="text-normal text-bold m-0">{item?.meta?.name || '******'}</p>
                <p className="text text-sm m-0">{item?.meta?.memo || '******'}</p>
                <p className="text-small text-sm m-0">{item?.webx?.number || '******'}</p>
                <div className="">
                  <hr className="m-0 mt-2 mb-2"></hr>
                </div>
              </div>
              <div className="ms-auto"></div>
            </div>

            <div className={x < data.length -1 ? 'd-none mt-2 mb-2 border-bottom' : ''}></div>

            </div>
            :''))}
          </div>

        </div>
      ))}
      </div>



    </>
  )
}
