import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { NumberFormat } from '../../services/srvc-utilities'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'
import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

export default function TransferSearchModule () {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [search, setSearch] = useState()
  const [data, setData] = useState({
    start: new Date().toISOString().substring(0, 10),
    end: new Date().toISOString().substring(0, 10),
    assets: true,
    funds: true,
    success: true,
    wait: false,
    all: false
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  return (
    <>
      {/* info */}
      <div className=''>Search</div>

      {/* dates */}
      <WebbDividerSmall />
      <div className='back-color-wite p-2 px-3 rounded-xd'>
        <div className='mb-3'>
          <label className='form-label text-small'>
            Start Date <FormNeeded />
          </label>
          <input
            type='date'
            className='form-control height-md rounded-wd'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data?.mail}
            onChange={({ target }) => {
              handleChange('mail', target.value)
            }}
            disabled={loader}
            placeholder='user@carbonize.cc'
          ></input>
        </div>

        <div className='mb-3'>
          <label className='form-label text-small'>
            End Date <FormNeeded />
          </label>
          <input
            type='date'
            className='form-control height-md rounded-wd'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data?.mail}
            onChange={({ target }) => {
              handleChange('mail', target.value)
            }}
            disabled={loader}
            placeholder='user@carbonize.cc'
          ></input>
        </div>
      </div>

      {/* format */}
      <WebbDividerSmall />
      <div className='back-color-wite p-2 px-3 rounded-xd'>
        <div className=''>
          <label className='form-label text-small'>
            Transfers <FormNeeded />
          </label>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Assets</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                checked={data.assets}
                onChange={() => ''}
              ></input>
            </div>
          </div>
          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Funds</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                checked={data.funds}
                onChange={() => ''}
              ></input>
            </div>
          </div>
        </div>
      </div>

      {/* status */}
      <WebbDividerSmall />
      <div className='back-color-wite p-2 px-3 rounded-xd'>
        <div className=''>
          <label className='form-label text-small'>
            Status <FormNeeded />
          </label>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Success</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                checked={data.success}
                onChange={() => ''}
              ></input>
            </div>
          </div>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>Pending</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                checked={data.wait}
                onChange={() => ''}
              ></input>
            </div>
          </div>

          <div className='d-flex form-check form-switch m-0 p-0 mb-2'>
            <div className=''>
              <p className='m-0 p-0'>All</p>
            </div>
            <div className='ms-auto'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                style={{ height: '1.2rem', width: '2rem' }}
                checked={data.all}
                onChange={() => ''}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
