// network
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { TeamContactList } from '../../services/srvc-users-creators-realm'


export default function NetworkListModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        // console.log ('transfers')

        const result = await TeamContactList({data: {
          team: asset.item, index: index, items: items,
          filters:{ name: props.search }
        }})
        console.log (result)

        if (result.stat ) {
          setData(result.data.list)
          
          const count = result.data.count
        
          setTotal(count)

          setText(`${((index-1) * items +1)} - ${index * items < count ? index * items : count} of ${count}`)
        }
        setLoader(false)
      }
      fetchData()
    } else {}
  }, [props.search, index, items])
  

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  const handleClick = async item => {
    navigate(`/${asset.role}/ux/${item}`)
  }
  console.log(data)

  if (loader)
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-wait'></i>
          </span>
          <span className='ms-1 text-color-tone'>Please Wait...</span>
        </div>
      </>
    )
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Contact List</span>
        </div>
      </>
    )

  return (
    <>
      <div className='back-color-wite rounded-xd border'>
        {/* header */}
        <div className='border-bottom text-bold'>
          <div className='d-flex p-2 px-3'>
            <div className='' style={{ width: '45%' }}>
              <p className='m-0 text-sm'>
                <span className=''>{'User Name'}</span>
              </p>
            </div>

            <div className='d-none d-md-block ms-2'>
              <p className='m-0 text-sm'>
                <span className=''>{'Role'}</span>
              </p>
            </div>

            <div className='ms-auto text-end text-sm'>
              <p className='m-0 text-sm'>
                <span className=''>{'Action'}</span>
              </p>
            </div>
          </div>
        </div>

        {/* data */}
        {data && data.map((item, i) => (
        <div className="cursor hilite" key={i} onClick={() => handleClick(item.item)}>
          <div className="d-flex p-2 px-3">

            <div className="mt-1 me-2 d-none">
              <span className="align-middle">
                <Jazzicon diameter={30} seed={jsNumberForAddress(item?.item || Date.now().toString())} /> 
              </span>
            </div>  

            <div className="d-flex" style={{width: '45%'}}>  
              
              <div className="mt-1 me-2">
                <span className="align-middle">
                  <Jazzicon diameter={30} seed={jsNumberForAddress(item?.item || Date.now().toString())} /> 
                </span>
              </div>
              <div className="">

                <p className="m-0 text-sm">
                  <span className="text-bold">{item.user.name}</span>
                </p>
                <p className="m-0 text-small text-sm">
                  <span className="">{item.user.mail}</span>
                </p>            
              </div>

            </div>

            <div className="d-none d-md-block ms-2">         
              <p className="mt-2 m-0 text-sm">
                <span className="">{item.role || 'member'}</span>
              </p>          
            </div>
            
            <div className="ms-auto text-end text-sm">         
              <p className="mt-2 m-0">
                <i className="bx bx-chevron-right text-normal"></i>
              </p>
            </div>

          </div>
          <div className={i < data.length-1 ? 'border-bottom': ''}></div>
        </div>
      ))}
      </div>

      {/* navs */}
      <WebbDividerSmall />
      <div className={data.length < items ? 'mx-3' : 'mx-3'}>
        <div className='d-flex justify-content-between'>
          <div className='' onClick={() => LastIndex()}>
            <NavsButtonBack />
          </div>

          <div className=''>
            <p className='my-3'>{text}</p>
          </div>

          <div className='' onClick={() => NextIndex()}>
            <NavsButtonNext />
          </div>
        </div>
      </div>
    </>
  )
}
