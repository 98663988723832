import React from 'react'
import { CSVLink } from 'react-csv'

export default function DownloadLink ({ download = {} }) {
  const { rows = [], cols = [], filename = 'myfile.csv' } = download
  return (
    <div className='text-normal align-middle'>
      <CSVLink data={rows} filename={filename} headers={cols}>
        <i className={`${`bi bi-download`}`}>&nbsp;Download</i>
      </CSVLink>
    </div>
  )
}
