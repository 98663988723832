// content
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { AssetUnits } from "../../services/srvc-assets-realm";

export default function AssetUnitsModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();
  const {id} = useParams()

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([])

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const datx = {asset: id, creator: asset.item, index: '1', items: '15'}
        const result = await AssetUnits({data: datx, srvc: '******'})
        // console.log(result)
        if (result.stat) setData(result.data.list)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <></>
  if (!loader && data.length == 0) return <>No Units</>

  return (
  <>
    
    <div className='row row-cols-1 row-cols-md-3 g-3'>
      {data && data.map((item, x) => (
      <div className="col m-0 mb-3" key={x}>
        
        <div className="card border-none rounded-xd h-100" 
          onClick={()=> handleClick(item.item)}>
          <div className='media-cube rounded-xd'>
            <img src={item.media.link} // Route of the image file
              layout='fill'
              className='img-fluid w-100'
              alt='...'
            />
            
            <div className='btn back-color-dark rounded-wd text-mini' 
              style={{backgroundColor: 'rgba(0,0,0,0.75)'}}>
              {item.webx.number}
            </div>
          </div>

          <div className='p-3 d-none'>
            <p className='text-mini m-0'>
              {item?.webx?.number || '******'}
            </p>
                  
            <p className='text-bold text-color-next text-sm mb-1'>
              {item.meta.name}
            </p>
            <p className='text-small text-md m-0'>{item.meta.memo}</p>
          </div>
        </div>

      </div>
      ))}

    </div>
  </>

  )
}