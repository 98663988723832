export const getDate = epoc => {
  if (!epoc) return ''
  return new Date(epoc).toLocaleDateString()
}
export const getRandom = (size = 10) => {
  return Math.floor(Math.random() * 9 * 10 ** (size - 1)) + 10 ** (size - 1)
}
export const captialize = (text = '') => {
  return text[0].toLocaleUpperCase() + text.slice(1)
}
export const getDummyImg = size =>
  `https://dummyimage.com/${size}/e7e2e2/000000.png&text=+`
export const STATUS_CODES = [
  {
    value: 0,
    text: 'pending',
    color: 'warning'
  },
  {
    value: 1,
    text: 'active',
    color: '-'
  },
  {
    value: 2,
    text: 'scheduled',
    color: '-'
  },
  {
    value: 3,
    text: 'locked',
    color: '-'
  },
  {
    value: 4,
    text: 'cancelled',
    color: 'danger'
  },
  {
    value: 5,
    text: 'timeout',
    color: '-'
  },
  {
    value: 6,
    text: 'completed',
    color: 'success'
  },
  {
    value: 7,
    text: 'declined',
    color: '-'
  },
  {
    value: 8,
    text: 'revoked',
    color: 'secondary'
  },
  {
    value: 9,
    text: 'transaction active (data)',
    color: '-'
  }
]
export const getQuery = key => {
  return new URLSearchParams(window.location.search).get(key) || ''
}
