export const DOMAIN = [
  { name: 'Age', item: 1, key: 'age', memo: 'age of the person' },
  {
    name: 'Annual Income',
    item: 2,
    key: 'annual_income',
    memo: 'Annual income of the person'
  },
  {
    name: 'Monthly Inhand Salary',
    item: 3,
    key: 'mon_inhand_salary',
    memo: 'Monthly inhand salary of the person'
  },
  {
    name: 'No of Bank Accounts',
    item: 4,
    key: 'no_bank_acc',
    memo: 'Total no, of bank accounts'
  },
  {
    name: 'No of Credit Cards',
    item: 5,
    key: 'no_of_credit_cards',
    memo: 'Total no. of credit cards'
  },
  {
    name: 'No of loans',
    item: 6,
    key: 'no_of_loans',
    memo: 'Total no. of loans taken'
  },
  {
    name: 'Outstanding Debt',
    item: 7,
    key: 'os_debt',
    memo: 'Outstanding debt amount'
  },
  {
    name: 'Amount Invested Monthly',
    item: 8,
    key: 'amt_invested_mon',
    memo: 'Monthly invested amount'
  },
  {
    name: 'Monthly Balance',
    item: 9,
    key: 'mon_balance',
    memo: 'Monthly balance'
  }
]

export const BASELINE = [
  { name: 'Auto Loan Model', item: 98, value: 'auto_loan_model' },
  { name: 'Mortgage Loan Model', item: 99, value: 'mortgage_loan_model' },
  { name: 'Payday Loan Model', item: 101, value: 'payday_loan_model' },
  { name: 'Personal Loan Model', item: 102, value: 'personal_loan_model' },
  { name: 'Student Loan Model', item: 103, value: 'student_loan_model' }
]
