import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import UserAvatar from '../content/webx/user-avatar'

import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'

import FormatsActionsModule from '../content/formats/formats-actions-consumer'
import FormatsListConsumerModule from '../content/formats/formats-list-consumerx'
import FormatsSearchModule from '../content/formats/formats-search'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

export default function Formats () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const metadata = {
    name: 'Formats',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: 'text-color-main', size: 'text-icon-sm' }}
                />
              </Link>
            </>
          ),
          data: (<>{' '}<WebbHeader />{' '}</>
          ),
          footer: (<><UserAvatar /></>)
        }}
        sidebar={{
          header: (<><p className='text-normal text-bold m-0'>{metadata.name}</p></>),
          data: (
            <>
              <FormatsSearchModule />
            </>
          ),
          footer: <>...</>
        }}
        content={{
          header: (
            <>
              <p className='m-0'>Data Formats</p>
            </>
          ),
          data: (
            <>
              <h2 className='text-normal text-color-next m-0'>My Formats</h2>
              
              <FormatsListConsumerModule />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <FormatsActionsModule />
            </>
          ),
          footer: <></>
        }}
      ></ContentFormat>
    </>
  )
}
