import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { FormatsCreate } from '../../services/srvc-formats-realm'
import { GetUserForm } from '../../services/srvc-utilities'
import { captialize } from '../../utils/utils'
import { DispalyLoder, Toast } from '../common/utils'

export default function PkgsViewConsumerModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')
  const [memoType, setMemoType] = useState('')
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        // setLoader(true)
        setData(props.data)
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  const formatClone = async item => {
    const isConfirmed = window.confirm(
      `Are you sure you want to clone ${item.name} format `
    )
    if (isConfirmed) {
      //create a format of selected format
      const vars = item?.vars?.map(({ taxxon }) => taxxon)
      const datx = {
        name: item.name,
        memo: item.memo,
        vars,
        valid: '24',
        role: 'user',
        domain: item.domain,
        consumer: asset.item
      }
      const result = await FormatsCreate({ data: datx, srvc: '******' })
      if (result.stat) {
        alert('Format cloned successfully')
        setDone(true)
        setMemo('Format Cloned')
        setMemoType('success')
      } else {
        setDone(false)
        setMemo('Failed')
        setMemoType('danger')
      }
    }
  }

  const FormatCards = item => {
    const taxxonStr = item?.vars?.map(taxxon => taxxon.name).join(', ')
    return (
      <>
        <div className='card m-2' style={{ width: '-webkit-fill-available' }}>
          <div className='card-body'>
            <h4 className='card-title'>{captialize(item?.name)}</h4>
            <hr />
            <div style={{ height: '200px' }}>
              <p className='card-text'>This format is for {item.memo}</p>
              <p className='card-text'>Data: {taxxonStr}</p>
            </div>
            <div className='d-flex justify-content-between'>
              <button
                className={`btn btn-light border back-color-wite rounded-xx text-small`}
                type='button'
                disabled={loader}
                onClick={e => formatClone(item)}
              >
                Select & Add
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (loader) return <DispalyLoder />

  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={'No Format Data'} />

  return (
    <>
      <p className='text-bold'></p>
      <div className='d-flex'>
        {data &&
          data.formats.map((item, x) => (
            <>
              <FormatCards {...item} key={item.item} />
            </>
          ))}
      </div>
      <Toast msg={memo} type={memoType} />
    </>
  )
}
