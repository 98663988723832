import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'
import { PkgsDetails } from '../../services/srvc-formats-realm'
import { getDate } from '../../utils/utils'
import Table from '../common/Table'
import { DispalyLoder, StatusBadge } from '../common/utils'

export default function PkgsListConsumerModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const header = ['name', 'memo', 'created', 'status', 'view']

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const res = await PkgsDetails({
          data: { code: '' }
        })
        const temp = res.data?.sort((a, b) => b.created - a.created)
        const transformedItem = temp.map(item => {
          const { name, memo, created, active } = item
          return {
            name,
            memo,
            created: getDate(created),
            status: <StatusBadge code={active ? 6 : 0} />,
            view: (
              <Link to={`/team/pkgs/${item.item}`} state={item}>
                View
              </Link>
            ),
            item: item.item
          }
        })
        setData({ header, body: transformedItem })
        setLoader(false)
        props.handleData(transformedItem)
      }
      fetchData()
    }
  }, [])

  if (loader) return <DispalyLoder />
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={'No Format Listed'} />

  return <Table data={data} />
}
