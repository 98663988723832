import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'

import UserAvatar from '../content/webx/user-avatar'
import WebbIcon from '../content/webx/webb-icon'
import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import WebbDividerMedium from '../content/webx/webb-divider-md'
import TransfersViewConsumerModule from '../content/transfers/transfers-viewx'
import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'
import TransfersViewSidebar from '../content/transfers/transfer-view-sidebar'
import TransfersActions from '../content/transfers/transfer-actions'
import TransferCodeModule from '../content/transfers/transfer-code'
import WebbDividerSmall from '../content/webx/webb-divider-sm'

export default function TransfersViewConsumer () {
  const metadata = {
    name: 'Transfers Details',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const { id } = useParams()
  const [data, setData] = useState()

  const handleData = async item => {
    setData(item)
  }
  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: 'text-color-main', size: 'text-icon-sm' }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              <WebbHeader />
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          )
        }}
        sidebar={{
          header: (
            <>
              <p className='text-normal text-bold m-0'>{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <TransfersViewSidebar {...data} />
            </>
          ),
          footer: <>...</>
        }}
        content={{
          header: (
            <>
              <p className='m-0'>Digital Transfers Details</p>
            </>
          ),
          data: (
            <>
              <h2 className='text-normal text-color-next mb-3'></h2>
              <TransfersViewConsumerModule handleData={handleData} />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              {/* <TransfersActions {...data} /> */}
              <p>Cancel Transaction</p>
              <p>Download Data</p>
              <p>Delete Data</p>
              <WebbDividerSmall />
              

              <div className=''>
                {/* <TransferCodeModule data={{ ...data }} /> */}
              </div>
            </>
          ),
          footer: <></>
        }}
      ></ContentFormat>
    </>
  )
}
