import React, { useEffect, useState } from 'react'
import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'
import WebbDividerSmall from '../webx/webb-divider-sm'
import { captialize } from '../../utils/utils'
import DownloadLink from './DownloadLink'

export default function Table ({ data, download = {}, showPagination = true }) {
  const { header, body } = data
  const { showDownload = false } = download

  const [page, setPage] = useState(1)
  const [startIndex, setStartIndex] = useState()
  const [endIndex, setEndIndex] = useState()
  
  const [limit] = useState(10)
  const [activeNext, setActiveNext] = useState(true)
  const [activePrev, setActivePrev] = useState(false)
  
  const [total, setTotal] = useState(body?.length)
  
  const [tableData, setTableData] = useState([])
  
  const next = () => {
    setPage(prev => prev + 1)
  }
  
  const prev = () => {
    setPage(prev => prev - 1)
  }

  const computeData = () => {
    const tx = { body: data?.body?.slice(startIndex, endIndex), header }
    setTableData(tx)
    setTotal(data?.body?.length)
  }
  const findIndex = async () => {
    if (page <= 1) {
      setStartIndex(0)
      setEndIndex(10)
    } else {
      setStartIndex((page - 1) * limit)
      setEndIndex(limit * page <= total ? limit * page : total)
    }
  }
  const checkActionButtons = () => {
    if (startIndex > 0) {
      setActivePrev(true)
    } else {
      setActivePrev(false)
    }
    if (endIndex >= total) {
      setActiveNext(false)
    } else {
      setActiveNext(true)
    }
  }

  useEffect(() => {
    findIndex()
  }, [data, page])

  useEffect(() => {
    computeData()
    checkActionButtons()
  }, [startIndex, endIndex, data])
  return (
    <>
      {showDownload && total > 0 && <DownloadLink download={download} />}
      <table className='table table-hover p-2'>
        <THead {...data} />
        <TBody {...tableData} />
      </table>
      <WebbDividerSmall />
      {/* Pagination */}
      {showPagination && (
        <div className={'p-2'}>
          <div className='d-flex justify-content-between'>
            <div className='' onClick={prev}>
              {activePrev && <NavsButtonBack />}
            </div>
            <div className=''>
              <p className='my-2 text-color-tone'>
                showing {`${startIndex + 1}-${endIndex}`} of {total || 0}
              </p>
            </div>
            <div className='' onClick={next}>
              {activeNext && <NavsButtonNext />}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const THead = ({ header = [] }) => {
  return (
    <thead>
      <tr className='m-0 p-0'>
        {header.map((head, index) => (
          <th key={index}>{captialize(head)}</th>
        ))}
      </tr>
    </thead>
  )
}
const TBody = ({ header = [], body = [] }) => {
  return (
    <tbody>
      {body &&
        body.map((item, row) => {
          return (
            <tr key={item?.number || row}>
              {header.map((head, col) => (
                <td key={col}>{item[head]}</td>
              ))}
            </tr>
          )
        })}
    </tbody>
  )
}
