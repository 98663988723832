// assets
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { AssetDetails } from "../../services/srvc-assets-realm";

const list = [
  {name: 'Total', code: 'count', number: '', ticker: '', active: false},
  {name: 'Mint', code: 'mint', number: '', ticker: '', active: true},
  {name: 'Book', code: 'book', number: '', ticker: '', active: true},
  {name: 'Sale', code: 'sale', number: '', ticker: '', active: true},
  {name: 'Revoke', code: 'burn', number: '', ticker: '', active: true}
]

const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'

export default function AssetViewHeaderModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();
  const {id} = useParams()

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()
  const [units, setUnits] = useState()


  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const result = await AssetDetails({data: {
          item: id, 
          index: 1, items: 25, 
          filters:{ name: {} }
        }})
        // console.log (result)
        if (result.stat) {
          setData(result.data)
          setUnits(Array.from(list, x => { return {
            ...x, number: result.data.units[x.code]
          }}))
          
          // send data
          props.data(result.data)

        }

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = async(item) => {
    // navigate(`/${asset.role}/ax/${item}`)
  }


  if (loader) return <>
    <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>
      
      <span className="align-middle text-lead">
        <i className="bx bxs-info-circle text-color-wait"></i>
      </span>
      <span className='ms-1 text-color-tone'>Please Wait...</span>
      
    </div>
  </>

  return (
    <>
      {/* info */}
    
      {/* asset media */}
      <div className="back-color-wite rounded-xd">
        <div className="media-standard rounded-xd">
          <img
            src={data.media.link} // Route of the image file
            layout='fill'
            className="img-fluid w-100 rounded"
            alt="..."
          />
        </div>  

      </div>

      {/* asset details */}
      <WebbDividerSmall />
      <div className="back-color-wite rounded-xd p-3">
        <p className="text-small m-0 mb-1">ID: {data?.webx?.number || '******'}</p>
        <h1 className="text-normal m-0 text-bold mb-1">{data?.meta?.name || '******'}</h1>
        <p className="m-0">{data?.meta?.memo || '******'}</p>  
        <WebbDividerSmall />
        <p className="text-mini m-0">
          <span>Created: </span>
          <span>{(new Date(parseInt(data?.created))).toLocaleDateString() || '******'}</span>
        </p>  
      </div>

      {/* asset units */}
      <WebbDividerSmall />
      <div className="back-color-wite rounded-xd p-3">
      <div className="row row-cols-4 row-cols-md-2 g-3">
          {units && units.map((item, i) => (item.active ?
          
          <div className="col" key={i}>

            <div className={`w-100 h-100 border-none m-0 p-0`}>
              
              <div className="d-flex">
                <div className="">
                  <p className="text-small m-0">{item.name}</p>
                  <p className="text-lead text-bold m-0">{item.number || '0'}</p>
                </div>
                <div className="ms-auto ">
                  <i className={`text-icon-md text-color-tint ${item.icon}`}></i>
                </div>
              </div>
              
            </div>

          </div>
          :''))}

        </div>
      </div>

    </>
  );

}