import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import FileCreateCX from '../../services/srvc-filecreate-cweb-xx'
import { FormatsCreate } from '../../services/srvc-formats-realm'
import { getTaxxoList } from '../../services/srvc-taxxo-realm'
import { GetUserForm } from '../../services/srvc-utilities'
import { TAXO_CATEGORIES, Toast } from '../common/utils'
import FormNeeded from '../webx/form-needed'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import TaxxonCard from './TaxxonCard'
import { DOMAIN, BASELINE } from './truescore'
import { ModelsList } from '../../services/srvc-cs-model-realm'

export default function FormatsCreateModule () {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')
  const [memoType, setMemoType] = useState('')
  const [taxxon, setTaxxon] = useState([])
  const [media, setMedia] = useState({})
  const [ts, setTs] = useState(false)
  const [format, setFormat] = useState([])
  const [truescore, setTruescore] = useState([])
  const [trueScoreDomain, setTrueScoreDomain] = useState(DOMAIN)
  const [modelList, setModelList] = useState([])

  const [data, setData] = useState({
    name: '',
    memo: '',
    format: '',
    sort: '',
    start: '',
    end: '',
    bs: ''
  })
  const TS_ID = '53166c172634486ca283049c8820e8267'
  const handleFormat = async item => {
    const temp = [...format]
    const index = temp.indexOf(item)
    if (index < 0) {
      temp.push(item)
    } else {
      temp.splice(index, 1)
    }
    setFormat(temp)
  }
  const handleTruscore = async item => {
    const temp = [...truescore]
    const index = temp.indexOf(item)
    if (index < 0) {
      temp.push(item)
    } else {
      temp.splice(index, 1)
    }
    setTruescore(temp)
  }
  useEffect(() => {
    const transformedItems = trueScoreDomain.map(item => {
      if (truescore.includes(item.item)) {
        return { ...item, selected: true }
      }
      return { ...item, selected: false }
    })

    setTrueScoreDomain(transformedItems)
  }, [truescore])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const result = await getTaxxoList({})
        if (result.stat) {
          // console.log('taxx', result.data.list)
          //categorise
          let transformedItems = TAXO_CATEGORIES.map(category => {
            const filter = result.data.list.filter(
              item => category.mainCode === parseInt(item.code[0])
            )
            if (filter.length) {
              return { domain: category.domain, list: filter || [] }
            }
          }).filter(item => item)
          setTaxxon(transformedItems)
        }
        setLoader(false)
      }
      fetchData()
    }
  }, [])
  useEffect(() => {
    if (asset) {
      const fetchDataModelsList = async () => {
        setLoader(true)
        const result = await ModelsList({ data: { consumer: asset.item } })
        if (result.stat) {
          console.log('model', result.data.list)
          setModelList(result.data.list)
        }

        setLoader(false)
      }
      fetchDataModelsList()
    }
  }, [taxxon])

  const handleSubmit = async () => {
    setLoader(true)
    setSubmit(true)
    setMemo('Please wait...')
    console.log(data)
    if (!format.length) {
      setMemo('Please select at least 1 data')
      setMemoType('warning')
      return alert('Please select at least 1 data')
    } else if (ts && !truescore.length && !data.bs) {
      return alert('err')
    }
    // const tsPayload =
    //   (ts &&
    //     DOMAIN.map(item =>
    //       truescore.includes(item.item) ? { ...item } : ''
    //     ).filter(item => item)) ||
    //   []

    const baseline =
      (ts && modelList.find(item => item?.bsmodel?.value === data.bs)) || ''
    const datx = {
      name: data.name,
      memo: data.memo,
      vars: format,
      valid: '24',
      role: 'user',
      domain: 'work',
      consumer: asset.item,
      bs: baseline
    }
    const result = await FormatsCreate({ data: datx, srvc: '******' })
    if (result.stat) {
      setDone(true)
      setMemo('Format Created')
      setMemoType('success')
    } else {
      setDone(false)
      setMemo('Format Creation Failed')
      setMemoType('danger')
    }

    setLoader(false)
  }

  useEffect(() => {
    const transformedItems = taxxon.map(item => {
      const newList = item.list.map(data => {
        if (format.includes(data.item)) {
          return { ...data, selected: true }
        }
        return { ...data, selected: false }
      })
      return { ...item, list: newList }
    })
    if (format.includes(TS_ID)) {
      setTs(true)
    } else {
      setTs(false)
      setTruescore([])
    }
    setTaxxon(transformedItems)
  }, [format])

  const handleMediaBanner = async filedata => {
    setMedia({ link: filedata?.file.link, mime: filedata?.file?.mime })
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const BaseLineUI = () => {
    return (
      <div className={''}>
        <p className='text-normal my-3'>Select Data Model</p>
        {modelList &&
          modelList.map(item => (
            <div className='mb-3 form-check' key={item.item}>
              <input
                type='radio'
                id={item.item}
                className='form-check-input'
                value={item?.bsmodel?.value}
                checked={item?.bsmodel?.value === data.bs}
                name='bs'
                onChange={e => handleChange('bs', e.target.value)}
              ></input>
              <label
                className='form-label text-bold text-uppercase m-0 ms-3'
                htmlFor={item.item}
              >
                {item.name} <FormNeeded />
              </label>
            </div>
          ))}
      </div>
    )
  }
  console.log('d', data)
  return (
    <>
      {/* info */}
      <div className='mx-3'>
        <h2 className='text-normal m-0'>Create Format</h2>
        <WebbDividerSmall />
      </div>

      <div
        className={
          submit
            ? 'back-color-wite p-3 rounded-xd'
            : 'back-color-wite p-3 rounded-xd'
        }
      >
        <div className={''}>
          <p className='text-normal m-0'>1. Enter Details</p>
          <WebbDividerSmall />

          <div className='mb-3 d-none'>
            <label className='form-label text-small'>
              Add Media <FormNeeded />
            </label>
            <FileCreateCX size='media-standard' media={handleMediaBanner} />
            <WebbDividerSmall />
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              Name <FormNeeded />
            </label>
            <input
              type='text'
              className='form-control height-md'
              style={{ fontSize: '0.9rem', height: '2.7rem' }}
              value={data.name}
              onChange={({ target }) => {
                handleChange('name', target.value)
              }}
              disabled={loader || submit}
              placeholder=''
            ></input>
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              Details <FormNeeded />
            </label>
            <textarea
              className='form-control'
              rows='3'
              style={{ fontSize: '0.9rem' }}
              value={data.memo}
              onChange={({ target }) => {
                handleChange('memo', target.value)
              }}
              disabled={loader || submit}
            ></textarea>
          </div>

          <div className='d-flex mb-3 d-none'>
            <div className='w-50 me-3'>
              <label className='form-label text-small'>
                Start Date <FormNeeded />
              </label>
              <input
                type='date'
                className='form-control height-md'
                style={{ fontSize: '0.9rem', height: '2.7rem' }}
                value={data.start}
                onChange={({ target }) => {
                  handleChange('start', target.value)
                }}
                disabled={loader || submit}
                placeholder=''
              ></input>
            </div>
            <div className='w-50'>
              <label className='form-label text-small'>
                End Date <FormNeeded />
              </label>
              <input
                type='date'
                className='form-control height-md'
                style={{ fontSize: '0.9rem', height: '2.7rem' }}
                value={data.end}
                onChange={({ target }) => {
                  handleChange('end', target.value)
                }}
                disabled={loader || submit}
                placeholder=''
              ></input>
            </div>
          </div>
        </div>

        <div className={''}>
          <WebbDividerMedium />
          <p className='text-normal m-0'>2. Select Data</p>
          <WebbDividerSmall />

          <div className='mb-3'>
            <label className='form-label text-small mb-3 d-none'>
              Select Domain <FormNeeded />
            </label>
            <div className=''>
              {taxxon.map((item, i) => {
                return (
                  <div className='' key={i}>
                    <p className='text-bold text-uppercase m-0 ms-3'>
                      {i + 1}
                      {'. '}
                      {item.domain}
                    </p>
                    <WebbDividerSmall />
                    <div className='row row-cols-2 row-cols-md-3 g-2'>
                      {item.list.map((taxxo, z) => (
                        <>
                          <div className='col' key={z}>
                            <TaxxonCard
                              {...{
                                ...taxxo,
                                handleFormat,
                                selectedFormat: format
                              }}
                              key={taxxo.item}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                    <WebbDividerMedium />
                    {ts && item.list.map(({ item }) => item).includes(TS_ID) ? (
                      <>
                        <BaseLineUI />
                      </>
                    ) : (
                      ''
                    )}
                    <WebbDividerMedium />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <WebbDividerMedium />
        <Toast msg={memo} type={memoType} />

        <div className={submit ? 'd-none' : ''}>
          <WebbDividerMedium />
          <div className='d-flex justify-content-between'>
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type='button'
              disabled={loader || submit}
              onClick={() => {
                navigate(-1)
              }}
            >
              {loader ? 'Please Wait...' : 'Cancel'}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type='button'
              disabled={false}
              onClick={() => {
                handleSubmit()
              }}
            >
              {loader ? 'Please Wait...' : 'Submit'}
            </button>
          </div>
        </div>
        <WebbDividerSmall />
      </div>
    </>
  )
}
