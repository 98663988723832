import { captialize } from '../../utils/utils'
import WebbDividerSmall from '../webx/webb-divider-sm'
import Input from './Input'

export const STATUS_CODES = [
  {
    code: 0,
    memo: 'created',
    icon: 'bx bxs-circle',
    color: 'text-color-tint',
    text: 'warning'
  },
  {
    code: 1,
    memo: 'active',
    icon: 'bx bxs-circle',
    color: 'text-color-wait',
    text: 'warning'
  },
  {
    code: 2,
    memo: 'scheduled',
    icon: 'bx bxs-info-circle',
    color: 'text-color-wait',
    text: ''
  },
  {
    code: 3,
    memo: 'locked / on-hold',
    icon: 'bx bxs-minus-circle',
    color: 'text-color-wait',
    text: ''
  },
  {
    code: 4,
    memo: 'cancelled',
    icon: 'bx bxs-x-circle',
    color: 'text-color-error',
    text: 'danger'
  },
  {
    code: 5,
    memo: 'timeout',
    icon: 'bx bxs-error-circle',
    color: 'text-color-error'
  },
  {
    code: 6,
    memo: 'closed (success)',
    icon: 'bx bxs-check-circle',
    color: 'text-color-success'
  },
  {
    code: 7,
    memo: 'declined (user)',
    icon: 'bx bxs-x-circle',
    color: 'text-color-error'
  },
  {
    code: 8,
    memo: 'revoked (user)',
    icon: 'bx bxs-x-circle',
    color: 'text-color-error'
  },
  {
    code: 9,
    memo: 'declined (user)',
    icon: 'bx bxs-right-arrow-circle',
    color: 'text-color-next'
  }
]
export const TAXO_CATEGORIES = [
  {
    domain: 'Identity',
    mainCode: 1
  },
  {
    domain: 'Finance',
    mainCode: 2
  },
  {
    domain: 'Social',
    mainCode: 3
  },
  {
    domain: 'Academics',
    mainCode: 4
  },
  {
    domain: 'Assets',
    mainCode: 6
  },
  {
    domain: 'Work',
    mainCode: 7
  },
  {
    domain: 'Health',
    mainCode: 8
  },
  {
    domain: 'Entities',
    mainCode: 9
  }
]
export const StatusBadge = ({ code = 0 }) => {
  const status = STATUS_CODES.find(x => x.code === code)
  return (
    <span className={`text-small me-2 ${status.color}`}>
      <i className={`text-small bx ${`bxs-circle text-${status.icon}`}`}></i>
    </span>
  )
}

export const DispalyLoder = ({ msg }) => {
  return (
    <div
      className='p-3 back-color-wite rounded-xd border align-middle'
      style={{}}
    >
      <span className='align-middle text-lead'>
        <i className='bx bxs-info-circle text-color-wait'></i>
      </span>
      <span className='ms-1 text-color-tone'>{msg || 'Please Wait...'}</span>
    </div>
  )
}

export const FormDisplay = ({ label, value, type, isDisabled }) => {
  return (
    <div className='mb-3'>
      <label className='form-label small'>{captialize(label) || ''}</label>
      <Input type={type} value={value} isDisabled={isDisabled} />
    </div>
  )
}

export const Toast = ({ msg, type = 'success' }) => (
  <>
    <div className=''>
      <p className={`m-0 text-${type}`}>{msg}</p>
    </div>
    <WebbDividerSmall />
  </>
)

export const DetailsPage = data => {
  const items = Object.keys(data)
  return (
    <div>
      {items.map((key, i) => (
        <p className='text-small m-0 mb-1' key={i}>
          {captialize(key)}: {data[key]}
        </p>
      ))}
    </div>
  )
}
