// init
import axios from 'axios'
import services from '../data.services/data-services-snbx.json'

const base = services.data.find(x => x.code == 'assets.forms').link

export const FormatsCreate = async item => {
  const basx = base + '/formats/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const FormatsDetails = async item => {
  const basx = base + '/formats/details'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const PkgsDetails = async item => {
  const basx = base + '/pkg/details'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const FormatsDelete = async item => {
  const basx = base + '/formats/delete'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const FormatsList = async item => {
  const basx = `${base}/formats/list`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const FormatsStatusLiveSet = async item => {
  const basx = base + '/formats/status/live/set'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
